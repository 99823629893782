<template>
  <span v-if="error != ''" class="error">{{error}}</span>
  <div id="loader" v-show="loading">
    <i class="fas fa-spinner fa-pulse"></i>
  </div>
  <div id="content" v-if="!loading">
    <div v-if="isEditor" id="name-div">
      <h3 v-show="!changingName">{{ name }}</h3>
      <input v-show="changingName" v-model="name" v-on:keyup.enter="changingName = false; saveTournament()" @focusout="changingName=false; saveTournament()" id='name-input'>
      <a @click="changeName()"><i class="fa-solid fa-pencil"></i></a>
      <label v-if="this.role == 'administrator'" id="owner">{{ owner }}</label>
      <div class="toggle-with-label">
        <span>Скрыть</span>
        <v-toggle v-model="hidden" :classes="{container: 'outline-none'}" on-label="on" off-label="off" @change="saveTournament()"/>
      </div>
      <div class="gear_setting_container" v-click-outside="() => settings_dropdown_expanded = false">
        <a class="gear_settings_expand_button" :class="{'expanded': settings_dropdown_expanded}" v-show="!(loading || scheduling || till_setup)"
        @mouseover="settings_dropdown_hover = true" @mouseout="settings_dropdown_hover = false"
        @click="settings_dropdown_expanded=!settings_dropdown_expanded"> 
          <i class="fa-solid fa-gear" :class="{'fa-spin-pulse': settings_dropdown_hover}"></i>
        </a>
        <div class="gear_settings_expand" v-show="settings_dropdown_expanded">
          <button @click="copyFrame" :disabled="frame_copied">
            <span v-if="frame_copied">скопирован</span>
            <span v-else>Cсылкa на фрейм</span>
          </button>
          <button @click="exportExcel">
            <span v-show="!exporting">Экспорт в Excel</span>
            <i class="fas fa-spinner fa-pulse" v-show="exporting"></i>
          </button>
          <button @click="changeTournamentStatus('finished')" v-if="status != 'finished'">
            <span v-show="!changing_status">Завершить турнир</span>
            <i class="fas fa-spinner fa-pulse" v-show="changing_status"></i>
          </button>
          <button @click="changeTournamentStatus('running')" v-else>
            <span v-show="!changing_status">Продолжить турнир</span>
            <i class="fas fa-spinner fa-pulse" v-show="changing_status"></i>
          </button>
          <button @click="deleteTournament">
            <span v-show="!deleting">Удалить турнир</span>
            <i class="fas fa-spinner fa-pulse" v-show="deleting"></i>
          </button>
        </div>
      </div>
    </div> 
    <h3 v-else>{{ name }}</h3>
    <div id="info-div" class="info-container">
      <div class="info-container-header">
        <span>Дата и место</span> 
      </div>
      <div class="info-container-content" v-if="!(clubLoading || specificClubLoading)">
        <label>Когда:</label>
        <Datepicker v-model="date" selectText="Выбрать" cancelText="Отменить" class="datepicker" v-if="isEditor" @update:model-value="saveTournament()"></Datepicker>
        <span v-else>{{formatFullDateTime(date.getTime())}}</span>
        <label v-if="club_id || isEditor">Где:</label>
        <router-link v-if="!isEditor && club_id && selectedClub" :to="`/clubs/${selectedClub.id}/info`">{{selectedClub.name}}</router-link>
        <v-select v-if="isEditor" v-show="!(clubLoading || specificClubLoading)" v-model="selectedClub" :options="clubs" label="name" @option:selected="saveTournament()"></v-select>
      </div>
      <div class="info-container-loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
    <div v-if="info || isEditor" id="info-div" class="info-container">
      <div class="info-container-header">
        <span>Регламент</span> 
      </div>
      <div class="info-container-content">
        <div v-if="!isEditor" v-html="info"></div>
        <v-select v-if="isEditor" v-model="selectedRegulation" :options="regulations" label="name" @option:selected="info=selectedRegulation.message; saveTournament()"></v-select>
        <div v-if="isEditor"><vue-editor v-model="info" :editorToolbar="customToolbar" @click="info_edit=true" @blur="saveTournament()"></vue-editor></div>
      </div>
    </div>
    <div id="game-type-div" class="info-container">
      <div class="info-container-header">
        <span>Игра</span> 
      </div>
      <div class="info-container-content">
        <label v-if="!isEditor && selectedGameType">{{`Дисциплина: ${selectedGameType.name}`}}</label> 
        <label v-if="!isEditor && selectedGameType">{{`Верх до: ${wb_till} ${selectedGameType.id == 2 ? 'шаров.' : ' побед.'}`}}</label>
        <label v-if="!isEditor && selectedGameType">{{`Низ до: ${lb_till} ${selectedGameType.id == 2 ? 'шаров.' : ' побед.'}`}}</label>

        <label v-if="isEditor">Дисциплина:</label>
        <v-select v-if="isEditor" v-show="!gameTypeLoading" v-model="selectedGameType" :options="gameTypes" label="name" :clearable="false" @option:selected="saveTournament()"></v-select>
        <div class="grid-container" v-if="isEditor">
          <span>Верх до: </span>
          <input type="number" min="1" v-model="wb_till" @input="saveTournament()">
          <span>{{selectedGameType && selectedGameType.id == 2 ? 'шаров.' : ' побед.'}}</span>
          <span>Низ до: </span>
          <input type="number" min="1" v-model="lb_till" @input="saveTournament()">
          <span>{{selectedGameType && selectedGameType.id == 2 ? 'шаров.' : ' побед.'}}</span>
        </div>
        <label v-if="isEditor">
          <input type="checkbox" min="1" v-model="team">
          <span>Командный турнир</span>
        </label>
      </div>
    </div>
    <div id="handicap-div" v-if="handicap_id || isEditor" class="info-container">
      <div class="info-container-header">
        <span>Гандикап</span> 
      </div>
      <div class="info-container-content" v-if="!(handicapLoading || specificHandicapLoading)">
        <router-link v-if="!isEditor && selectedHandicap" :to="`/handicaps/${selectedHandicap.id}/participants`">{{selectedHandicap.name}}</router-link>
        <v-select v-else v-show="!(handicapLoading || specificHandicapLoading)" v-model="selectedHandicap" :options="handicaps" label="name"></v-select>
      </div>
      <div class="info-container-loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
    <div id="rating-div" v-if="rating_id || isEditor" class="info-container">
      <div class="info-container-header">
        <span>Рейтинг</span> 
      </div>
      <div class="info-container-content" v-if="!(ratingLoading && specificRatingLoading)">
        <router-link v-if="!isEditor && selectedRating" :to="`/ratings/${selectedRating.id}/participants`">{{selectedRating.name}}</router-link>
        <label v-if="!isEditor && rating_final">Итоговый турнир</label>
        <label v-if="!isEditor && !rating_final">{{`Коэффициент начисления очков: ${rating_ratio ? rating_ratio : '1'}`}}</label>        
        <v-select v-if="isEditor" v-show="!(specificRatingLoading || ratingLoading)" v-model="selectedRating" :options="ratings" label="name"></v-select>
        <div v-if="isEditor" id="rating-final">
          <input type="checkbox" v-model="rating_final"/>
          <label>Итоговый турнир</label>
        </div>
        <label v-if="isEditor" v-show="selectedRating && !rating_final">
          <span>Коэффициент начисления очков: </span>
          <input type="number" min="0.5" step="0.5" v-model="rating_ratio" @input="saveTournament()">
        </label>
      </div>
      <div class="info-container-loader" v-else>
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Toggle from '@vueform/toggle';
import "@vueform/toggle/themes/default.css";
import XLSX from "xlsx-js-style";
import { VueEditor } from "vue3-editor";
export default {
  name: 'TournamentInfo',
  components: {
    'Datepicker': Datepicker,
    'v-select': vSelect,
    'v-toggle': Toggle,
    'vue-editor': VueEditor
  },
  data: function() {
    return {
      get username() {
        let result =  'guest';
        if (localStorage.username) result = localStorage.username;
        return result;
      },
      get role() {
        let result = null;
        if (localStorage.role) result = localStorage.role;
        return result;
      },
      id: null,
      name: '',
      owner: '',
      info: '',
      info_edit: false,
      loading: false,
      exporting: false,
      saving: false,
      deleting: false,
      error: '',
      handicapLoading: false,
      specificHandicapLoading: false,
      handicaps: [],
      selectedHandicap: null,
      handicap_id: null,
      game_type_id: null,
      gameTypes: [],
      gameTypeLoading: false,
      selectedGameType: null,
      wb_till: null,
      lb_till: null,
      frame_copied: false,
      selectedRating: null,
      ratingLoading: false,
      specificRatingLoading: false,
      rating_ratio: 1,
      rating_final: false,
      ratings: [],
      changingName: false,
      date: null,
      clubs: [],
      club_id: null,
      clubLoading: false,
      specificClubLoading: false,
      selectedClub: null,
      hidden: false,
      team: false,
      selectedRegulation: null,
      regulations: null,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" }
        ],
        [{ color: [] }, { background: [] }],
        ["link"]
      ],
      settings_dropdown_expanded: false,
      settings_dropdown_hover: false,
      changing_status: false,
      changing_team_mode: false
    }
  },
  watch: {
    selectedHandicap() {
      if (!(this.handicapLoading || this.specificHandicapLoading || this.loading)) {
        this.saveTournament();
      }
    },
    selectedRating() {
      if (!(this.ratingLoading || this.specificRatingLoading || this.loading)) {
        this.saveTournament();
      }
    },
    selectedClub() {
      if (!(this.clubLoading || this.specificClubLoading || this.loading)) {
        this.saveTournament();
      }
    },
    team() {
      if (!this.loading && !this.changing_team_mode) {
        if (this.participants.length || this.games.length) {
          let message = '';
          if (this.team) {
            message = `В вашем турнире уже есть зарегистрированные игроки. При включении команного режима они будут удалены из турнира.\nВы уверены, что хотите продолжить?`
          } else {
            message = `В вашем турнире уже есть зарегистрированные команды. При выключении команного режима они будут удалены из турнира.\nВы уверены, что хотите продолжить?`
          }
          if(confirm(message)) {
            this.saveTournament();
          } else {
            this.changing_team_mode = true;
            console.log(this.team);
            setTimeout(() => this.team = !this.team, 200);
            setTimeout(() => {this.changing_team_mode = false; console.log(this.team)}, 500);
          }
        } else {      
          this.saveTournament();
        }
      }
    },
    rating_final() {
      if (!this.loading) this.saveTournament();
    }
  },
  methods: {
    getTournament() {
      this.loading = true;
      this.name = '';
      this.info = '';
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        this.id = response.data.id;
        this.status = response.data.status;
        this.name = response.data.name;
        if (response.data.info) this.info = response.data.info;
        this.owner = response.data.owner;
        this.handicap_id = response.data.handicap_id;
        if (this.handicap_id) this.getHandicap(this.handicap_id);
        this.rating_id = response.data.rating_id;
        if (this.rating_id) this.getRating(this.rating_id);
        this.rating_final = response.data.rating_final;
        if (response.data.game_type) this.game_type_id = response.data.game_type;
        else this.game_type_id = 1;
        this.wb_till = response.data.wb_till;
        this.lb_till = response.data.lb_till;
        if (response.data.rating_ratio) this.rating_ratio = response.data.rating_ratio;
        else this.rating_ratio = 1;
        if (response.data.start_time) this.date = new Date(parseInt(response.data.start_time,10));
        else this.date = new Date();
        this.club_id = response.data.club_id;
        if (this.club_id) this.getClub(this.club_id);
        if (response.data.hidden) this.hidden = true;
        else this.hidden = false;
        if (response.data.team) this.team = response.data.team;
        else this.team = false;
        this.participants = response.data.participants;
        this.games = response.data.games;
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => {
        if (this.isEditor) {
          this.getHandicaps();
          this.getRatings();
          this.getClubs();
          this.getRegulations();
        }
        this.getGameTypes();
        this.loading = false
      })
    },
    saveTournament() {
      this.saving = true;
      this.error = '';
      let handicap_id = null;
      if (this.selectedHandicap) handicap_id = this.selectedHandicap.id;  
      let rating_id = null;
      if (this.selectedRating) rating_id = this.selectedRating.id;  
      let game_type = null;
      if (this.selectedGameType.id) game_type = this.selectedGameType.id;
      let club_id = null;
      if (this.selectedClub && this.selectedClub.id) club_id = this.selectedClub.id;
      let start_time = null;
      if (this.date) start_time = this.date.getTime();
      let tournament = {
        id: this.id,
        name: this.name,
        info: this.info,
        handicap_id: handicap_id,
        rating_id: rating_id,
        rating_final: this.rating_final,
        rating_ratio: this.rating_ratio,
        game_type: game_type,
        wb_till: this.wb_till,
        lb_till: this.lb_till,
        start_time: start_time,
        club_id: club_id,
        hidden: this.hidden,
        team: this.team,
      }
      axios
      .post('/api/tournament/edit', {tournament: tournament}, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.id = response.data.id;
        this.name = response.data.name;
        this.info = response.data.info;
        this.owner = response.data.owner;
        this.game_type = response.data.game_type;
        this.wb_till = response.data.wb_till;
        this.lb_till = response.data.lb_till;
        this.rating_ratio = response.data.rating_ratio;
        if (response.data.hidden) this.hidden = true;
        else this.hidden = false;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось сохранить турнир'
        }
      })
      .finally(() => this.saving = false)
    },
    deleteTournament() {
      if (confirm('Вы уверены что хотите удалить турнир?')) {
        this.deleting = true;
        this.error = '';
        axios
        .post('/api/tournament/delete', {id: this.id}, {headers: {'x-access-token': localStorage.token}})
        .then(() => {
          this.$router.replace({ path: '/tournaments' })
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
              localStorage.removeItem('username');
              localStorage.removeItem('name');
              localStorage.removeItem('role');
              localStorage.removeItem('token');
              this.$router.push({ path: '/login' })
              return;
            }
            this.deleteTournamentError = error.response.data;
          } else {
            this.deleteTournamentError = 'Не получилось удалить турнир'
          }
        })
        .finally(() => this.deletingTournament = false)
      }
    },
    getHandicaps() {
      this.handicapLoading = true;
      this.error = '';
      this.handicaps = [];
      axios
      .get('/api/handicap/list/mine', {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.handicaps = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить гандикапы'
        }
      })
      .finally(() => this.handicapLoading = false)
    },
    getRatings() {
      this.ratingLoading = true;
      this.error = '';
      this.ratings = [];
      let url = '/api/rating/list/mine';
      // if (this.role == 'administrator') url = '/api/rating/list';
      axios
      .get(url, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.ratings = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить рейтинги'
        }
      })
      .finally(() => this.ratingLoading = false)
    },
    getHandicap(id) {
      this.specificHandicapLoading = true;
      this.error = '';
      axios
      .get('/api/handicap/get', { params: { id: id } })
      .then(response => {
        this.selectedHandicap = response.data[0]
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.specificHandicapLoading = false)
    },
    getClubs() {
      this.clubLoading = true;
      this.error = '';
      this.clubs = [];
      let url = '/api/club/list/mine';
      if (localStorage.role == 'administrator') url = '/api/club/list';
      axios
      .get(url, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.clubs = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить клубы'
        }
      })
      .finally(() => this.clubLoading = false)
    },
    getRegulations() {
      this.error = '';
      this.regulations = [];
      let url = '/api/tournament/regulation/list';
      axios
      .get(url, {headers: {'x-access-token': localStorage.token}})
      .then(response => {
        this.regulations = response.data;
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить регламенты'
        }
      })
    },
    getClub(id) {
      this.specificClubLoading = true;
      this.error = '';
      axios
      .get('/api/club/get', { params: { id: id } })
      .then(response => {
        this.selectedClub = response.data
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.specificClubLoading = false)
    },
    getRating(id) {
      this.specificRatingLoading = true;
      this.error = '';
      axios
      .get('/api/rating/get', { params: { id: id } })
      .then(response => {
        this.selectedRating = response.data[0]
      })
      .catch(error => {
        console.log(error);
        this.error = error.response.data;
      })
      .finally(() => this.specificRatingLoading = false)
    },
    getGameTypes() {
      this.gameTypeLoading = true;
      this.error = '';
      this.gameTypes = [];
      axios
      .get('/api/tournament/get/gametypes')
      .then(response => {
        this.gameTypes = response.data;
        if (this.game_type_id) {
          for (let i = 0; i < this.gameTypes.length; i++) {
            if (this.gameTypes[i].id == this.game_type_id) {
              this.selectedGameType = this.gameTypes[i];
              break
            }
          }
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          this.error = error.response.data;
        } else {
          this.error = 'Не получилось запросить типы игр'
        }
      })
      .finally(() => this.gameTypeLoading = false)
    },
    copyFrame() {
      navigator.clipboard.writeText(`<iframe frameborder='0' width='640' height='480' src='https://fairplay.host/tournaments/${this.id}/chart?menu=hide'></iframe>`)
      this.frame_copied = true;
      setTimeout(() => {this.frame_copied = false}, 1000);
    },
    changeName() {
      this.changingName=!this.changingName;
      if (this.changingName) {
        let input = document.getElementById('name-input');
        setTimeout(function() { input.focus() }, 1);
      }
    },
    formatFullDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month}.${year} ${hour}:${min}`;
      return result
    },
    formatDateTime(timestamp) {
      if (!timestamp) return ''
      let date = new Date(parseInt(timestamp,10));
      let month = date.getMonth() + 1;
      if (month.toString().length == 1) month = '0' + month;
      let day = date.getDate();
      if (day.toString().length == 1) day = '0' + day;
      let hour = date.getHours();
      if (hour.toString().length == 1) hour = '0' + hour;
      let min = date.getMinutes();
      if (min.toString().length == 1) min = '0' + min;
      let result = `${day}.${month} ${hour}:${min}`;
      return result
    },
    groupArrayBy(array, field) {
      let result = {}
      array.forEach(item => {
        if (!result[item[field]]) {
          result[item[field]] = [];
        }
        result[item[field]].push(item);
      });
      return result
    },
    formatName(name) {
      let result;
      let parts = name.split(' ');
      if (parts.length > 2) {
        if (parts[1].match(/[А-Я]/)) {
          result = parts[0] + ' ' + parts[1];
        } else {
          result = parts[0] + ' ' + parts[1] + ' ' + parts[2];
        }
      } else {
        result = name;
      }
      return result
    },
    exportExcel() {
      this.exporting = true;
      axios
      .get('/api/tournament/details', { params: { id: this.$route.params.id } })
      .then(response => {
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, this.createParticipantsWorksheet(response.data.participants, response.data.teams), "Участники");
        XLSX.utils.book_append_sheet(workbook, this.createGamesWorksheet(response.data.games, response.data.participants, response.data.teams), "Сетка");
        XLSX.writeFile(workbook, `${this.name}.xlsx`);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.exporting = false;
      })
    },
    createGamesWorksheet(games, participants, teams) {
      const games_worksheet = XLSX.utils.json_to_sheet([]);
      let participants_by_id = this.groupArrayBy(participants, 'id')
      let teams_by_id = null;
      if (this.team) {
        teams_by_id = this.groupArrayBy(teams, 'id')
      }
      let space_column_width = 10;
      let lot_column_width = 10;
      let country_column_width = 3;
      let player_column_width = this.team ? Math.max(...teams.map(o => o.name.length)) : Math.max(...participants.map(o => this.formatName(o.name).length));
      let score_column_width = 3;
      let cols = [];
      for (let i=0; i<30; i++) {
        cols.push({wch:space_column_width});
        cols.push({wch:lot_column_width});
        cols.push({wch:country_column_width});
        cols.push({wch:player_column_width});
        cols.push({wch:score_column_width});
      }
      games_worksheet['!cols'] = cols;
      let table_number_style = { alignment: { horizontal: 'center' } }
      let game_number_style = { fill: { fgColor: { rgb: "2F8130" } }, alignment: { horizontal: 'center' } }
      let start_time_style = { alignment: { horizontal: 'center' } }
      let lot_style = { alignment: { horizontal: 'right' } }
      let lot_connect_style = { alignment: { horizontal: 'right' }, border: {bottom: {style: 'thin'} }}
      let country_style = { alignment: { horizontal: 'center' }, border: {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}}}
      let player_style = { alignment: { horizontal: 'left' }, border: {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}}}
      let score_style = { alignment: { horizontal: 'center' }, border: {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}}}
      let connect_right_style = {border: {right: {style: "thin"}}};
      let connect_right_bottom_style = {border: {right: {style: "thin"}, bottom: {style: "thin"}}};
      let connect_right_top_style = {border: {right: {style: "thin"}, top: {style: "thin"}}};
      let connect_bottom_style = {border: {bottom: {style: "thin"}}};

      let description_style = { alignment: { horizontal: 'center' } }
      games.forEach(game => {
        if (game.stage == 'group') return
        let game_location_x = 5 * (game.location_x - 1) + 1
        let game_location_y = 4 * (game.location_y - 1) + 1
        let table_addr = XLSX.utils.encode_cell({r:game_location_y, c:game_location_x + 2});
        let game_number_addr = XLSX.utils.encode_cell({r:game_location_y + 1, c:game_location_x + 1});
        let start_time_addr = XLSX.utils.encode_cell({r:game_location_y + 1, c:game_location_x + 2});
        let lot1_addr = XLSX.utils.encode_cell({r:game_location_y + 2, c:game_location_x});
        let country1_addr = XLSX.utils.encode_cell({r:game_location_y + 2, c:game_location_x + 1});
        let player1_addr = XLSX.utils.encode_cell({r:game_location_y + 2, c:game_location_x + 2});
        let score1_addr = XLSX.utils.encode_cell({r:game_location_y + 2, c:game_location_x + 3});
        let lot2_addr = XLSX.utils.encode_cell({r:game_location_y + 3, c:game_location_x});
        let country2_addr = XLSX.utils.encode_cell({r:game_location_y + 3, c:game_location_x + 1});
        let player2_addr = XLSX.utils.encode_cell({r:game_location_y + 3, c:game_location_x + 2});
        let score2_addr = XLSX.utils.encode_cell({r:game_location_y + 3, c:game_location_x + 3});
        let description_addr = XLSX.utils.encode_cell({r:game_location_y + 4, c:game_location_x + 2});
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.table_number ? `стол ${game.table_number}` : ""]], {origin: table_addr});
        games_worksheet[table_addr].s = table_number_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.game_number]], {origin: game_number_addr});
        games_worksheet[game_number_addr].s = game_number_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.start_time ? this.formatDateTime(game.start_time) : ""]], {origin: start_time_addr});
        games_worksheet[start_time_addr].s = start_time_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.player1_lot ? game.player1_lot : ""]], {origin: lot1_addr});
        if (game.stage == 'P3' || game.location_x == 1) {
          games_worksheet[lot1_addr].s = lot_style;
        } else {
          games_worksheet[lot1_addr].s = lot_connect_style;
        }
        let player1_country_label = "";
        if (!this.team && game.player1 != '0') player1_country_label = participants_by_id[game.player1][0].country_label ? participants_by_id[game.player1][0].country_label : ""
        XLSX.utils.sheet_add_aoa(games_worksheet, [[player1_country_label]], {origin: country1_addr});
        games_worksheet[country1_addr].s = country_style;
        let player1_name = 'X';
        if (game.player1 != '0') {
          if (this.team) {
            player1_name = teams_by_id[participants_by_id[game.player1][0].team_id][0].name
          } else {
            player1_name = this.formatName(participants_by_id[game.player1][0].name)
          }
        }
        XLSX.utils.sheet_add_aoa(games_worksheet, [[player1_name]], {origin: player1_addr});
        games_worksheet[player1_addr].s = player_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.player1_score != null ? game.player1_score : ""]], {origin: score1_addr});
        games_worksheet[score1_addr].s = score_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.player2_lot ? game.player2_lot : ""]], {origin: lot2_addr});
        games_worksheet[lot2_addr].s = lot_style;
        let player2_country_label = "";
        if (!this.team && game.player2 != '0') player2_country_label = participants_by_id[game.player2][0].country_label ? participants_by_id[game.player2][0].country_label : ""
        XLSX.utils.sheet_add_aoa(games_worksheet, [[player2_country_label]], {origin: country2_addr});
        games_worksheet[country2_addr].s = country_style;
        let player2_name = 'X';
        if (game.player2 != '0') {
          if (this.team) {
            player2_name = teams_by_id[participants_by_id[game.player2][0].team_id][0].name
          } else {
            player2_name = this.formatName(participants_by_id[game.player2][0].name)
          }
        }
        XLSX.utils.sheet_add_aoa(games_worksheet, [[player2_name]], {origin: player2_addr});
        games_worksheet[player2_addr].s = player_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.player2_score != null ? game.player2_score : ""]], {origin: score2_addr});
        games_worksheet[score2_addr].s = score_style;
        XLSX.utils.sheet_add_aoa(games_worksheet, [[game.description ? game.description.replace('loser to', 'проигр. на').replace('winner to', 'побед. на') : ""]], {origin: description_addr});
        games_worksheet[description_addr].s = description_style;

        //Draw vertical lines
        if (game.stage == 'P3') return
        let neighbors = [];
        games.forEach(neighbor_game => {
          if (neighbor_game.game_number == game.game_number) return;
          if (neighbor_game.stage == "P3") return;
          if (neighbor_game.location_x - game.location_x != 1) return;
          if (neighbors.length == 0) {
            neighbors.push(neighbor_game);
            return;
          }
          if (Math.abs(neighbor_game.location_y - game.location_y) > Math.abs(neighbors[0].location_y - game.location_y)) {
            return;
          } else if (Math.abs(neighbor_game.location_y - game.location_y) == Math.abs(neighbors[0].location_y - game.location_y)) {
            neighbors.push(neighbor_game);
          } else {
            neighbors = [];
            neighbors.push(neighbor_game);
          }
        });
        neighbors.forEach(neighbor_game => {
          let neighbor_game_location_y = 4 * (neighbor_game.location_y - 1) + 1
          if (game.location_y < neighbor_game.location_y) {
            for (let y = game_location_y + 3; y < neighbor_game_location_y + 3; y++) {
              let addr = XLSX.utils.encode_cell({r:y, c:game_location_x + 4});
              XLSX.utils.sheet_add_aoa(games_worksheet, [[""]], {origin: addr});
              if (y == game_location_y + 3) {
                games_worksheet[addr].s = connect_right_top_style;
              } else {
                games_worksheet[addr].s = connect_right_style;
              }
            }
          } else if (game.location_y > neighbor_game.location_y) {
            for (let y = neighbor_game_location_y + 3; y < game_location_y + 3; y++) {
              let addr = XLSX.utils.encode_cell({r:y, c:game_location_x + 4});
              XLSX.utils.sheet_add_aoa(games_worksheet, [[""]], {origin: addr});
              if (y == game_location_y + 2) {
                games_worksheet[addr].s = connect_right_bottom_style;
              } else {
                games_worksheet[addr].s = connect_right_style;
              }
            }
          } else {
            let addr = XLSX.utils.encode_cell({r:game_location_y + 2, c:game_location_x + 4});
            XLSX.utils.sheet_add_aoa(games_worksheet, [[""]], {origin: addr});
            games_worksheet[addr].s = connect_bottom_style;
          }
        });
      })
      return games_worksheet;
    },
    createParticipantsWorksheet(participants, teams) {
      let name_width = 3;
      let rank_width = 9;
      let country_width = 6;
      let region_width = 6;
      let city_width = 5;
      let lot_width = 9;
      let place_width = 7;
      let points_width = 6;
      let teams_by_id;
      if (teams && teams.length) teams_by_id = this.groupArrayBy(teams, 'id');
      let participants_json = participants.map(p => {
        let part_name = "";
        if (p.team_id) {
          part_name = teams_by_id[p.team_id][0].name;
        } else if (p.name) {
          part_name = p.name;
        }
        let part = {
          "Имя": part_name,
          "Звание": p.rank ? p.rank : "",
          "Страна": p.country_name ? p.country_name : "",
          "Регион": p.region ? p.region : "",
          "Город": p.city ? p.city : "",
          "Жребий": p.lot ? p.lot : "",
          "Место": p.place ? p.place : "",
          "Очки": p.points ? p.points : ""
        }
        name_width = Math.max(name_width, part["Имя"].length);
        rank_width = Math.max(rank_width, part["Звание"].length);
        country_width = Math.max(country_width, part["Страна"].length);
        region_width = Math.max(region_width, part["Регион"].length);
        city_width = Math.max(city_width, part["Город"].length);
        lot_width = Math.max(lot_width, part["Жребий"].toString().length);
        place_width = Math.max(place_width, part["Место"].toString().length);
        points_width = Math.max(points_width, part["Очки"].toString().length);
        return part;
      });
      participants_json.sort((a,b) => b["Очки"] - a["Очки"]);
      const participants_worksheet = XLSX.utils.json_to_sheet(participants_json);
      participants_worksheet['!cols'] = [{wch: name_width}, {wch:rank_width}, {wch:country_width}, {wch:region_width}, {wch:city_width}, {wch:lot_width}, {wch:place_width}, {wch:points_width}];
      let cell_style = { border: {top: {style: 'thin'}, bottom: {style: 'thin'}, left: {style: 'thin'}, right: {style: 'thin'}}}
      let header_style = { border: {top: {style: 'thin'}, bottom: {style: 'medium'}, left: {style: 'thin'}, right: {style: 'thin'}}, fill: {fgColor: {rgb: "E9E9E9"}}, font: {sz: "14"}}
      for (let c = 0; c<8; c++) {
        for (let r=0; r<participants_json.length + 1; r++) {
          let addr = XLSX.utils.encode_cell({r:r, c:c});
          if (r == 0) {
            participants_worksheet[addr].s = header_style;
          } else {
            participants_worksheet[addr].s = cell_style;
          }
        }
      }
      return participants_worksheet;
    },
    changeTournamentStatus(status) {
      this.changing_status = true;
      this.error = '';
      axios
      .post('/api/tournament/status/edit', {id: this.id, status: status}, {headers: {'x-access-token': localStorage.token}})
      .then(() => this.status = status)
      .catch(error => {
        console.log(error);
        if (error.response) {
          if (error.response.data == 'Invalid Token' || error.response.data == 'A token is required for authentication') {
            localStorage.removeItem('username');
            localStorage.removeItem('name');
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            this.$router.push({ path: '/login' })
            return;
          }
          this.error = error.response.data;
        }
      })
      .finally(() => this.changing_status = false)
    }
  },
  computed: {
    isEditor() {
      return this.role == 'administrator' || (this.role == 'operator' && this.owner == this.username)
    }
  },
  created() {
    this.getTournament();
  }
}
</script>

<style scoped>
  #content {
    height: 100%;
  }
  textarea {
    height: 200px;
  }
  .v-select {
    flex-grow: 1;
  }
  .info-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 5px #ccc;
    display: flex;
    flex-direction: column;
    margin: 5px;
    overflow: visible;
  }
  .info-container-header {
    border-bottom: 1px solid #ccc;
    align-self: stretch;
    padding: 10px;
    background: #f2f2f2;
    color: #0e7c0d;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 14px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
  }
  .info-container-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: 10px;
  }
  .info-container-content > label,
  #rating-final > label {
    color: rgb(103, 103, 103);
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  #rating-final {
    display: flex;
    align-items: center;
  }
  .info-container-content * {
    font-size: 12px;
  }
  .info-container-content > * {
    margin: 5px;
  }
  .info-container-loader {
    display: flex;
    justify-content: center;
    font-size: 20px;
    padding: 10px;
  }
  .toggle-with-label {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .toggle-with-label span {
    margin: 0 5px;
    font-size: 10px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #445142;
  }
  #owner {
    background-color: #f3f2f2;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 5px;
    color: #3b3b3b;
  }
  a.gear_settings_expand_button {
    margin-left: 10px !important;
  }
  .gear_settings_expand {
    width: 190px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: max-content max-content max-content;
  }
  .grid-container > * {
    margin: 2px 2px;
    text-align: center;
  }
  .grid-container > input {
    width: 50px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>